import { memo, useCallback, useState, type ComponentProps, type MouseEvent, type ReactNode } from 'react'

import styled from '@emotion/styled'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { colorTokens, LegacyDialog, spacing, useDialogVisibility, useScreenType } from '../../../index.js'

import { colors } from '../../../colors.js'

import { Avatar } from '../../atoms/avatar/avatar.js'
import { Button } from '../../button/index.js'

const MenuButton = styled(Button)<{ maxWidth?: string }>`
  ${({ maxWidth = undefined }) => (maxWidth ? `max-width: ${maxWidth};` : ``)}
  overflow: hidden;
  padding: 0;

  & > div > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${spacing[40]};
    padding: ${spacing[40]};
    max-width: 240px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const DropdownMenu = memo<{
  menuItems: ComponentProps<typeof FloatingMenuList>['menuItems']
  buttonAccessoryLeft?: ReactNode
  buttonAccessoryRight?: ReactNode
  buttonLabel?: string
  buttonMaxWidth?: string
  onChange: (value: string | null) => void
  forceOpen?: boolean
}>(function UserDropdown({
  buttonAccessoryLeft,
  buttonAccessoryRight,
  buttonLabel,
  buttonMaxWidth,
  menuItems,
  forceOpen = false,
  onChange,
}) {
  const isMobile = useScreenType() === 'mobile'
  const { show, hide, visible } = useDialogVisibility('topbar-menu')

  const open = forceOpen || visible

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
      show()
    },
    [setAnchorEl, show],
  )
  const handleClose = useCallback(() => {
    hide()
  }, [hide])

  return (
    <>
      <MenuButton
        variant={isMobile ? 'text' : 'secondary'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        maxWidth={buttonMaxWidth}
      >
        {buttonAccessoryLeft}
        {isMobile ? (
          <></>
        ) : (
          <>
            {buttonLabel}
            {buttonAccessoryRight}
          </>
        )}
      </MenuButton>

      {isMobile ? (
        <DialogMenuList
          menuItems={menuItems}
          anchorElement={anchorEl}
          open={open}
          handleClose={handleClose}
          onChange={onChange}
        />
      ) : (
        <FloatingMenuList
          menuItems={menuItems}
          anchorElement={anchorEl}
          open={open}
          handleClose={handleClose}
          onChange={onChange}
        />
      )}
    </>
  )
})

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    margin-top: ${spacing[30]};
    border-radius: ${spacing[30]};
    border: 1px solid ${colorTokens['color-stroke-base']};
    background: ${colors.white};

    /* Shadow/Down/Shadow300 */
    box-shadow:
      0 2px 2px 0 rgb(0 0 99 / 5%),
      0 4px 6px 0 rgb(0 0 99 / 5%),
      0 8px 8px 0 rgb(0 0 99 / 3%),
      0 16px 12px 0 rgb(0 0 99 / 1%),
      0 28px 18px 0 rgb(0 0 99 / 1%);
  }
`

const FloatingMenuList = memo<{
  menuItems: Array<{
    icon?: ComponentProps<typeof Avatar>['icon']
    label: string
    value: string
  }>
  handleClose: () => void
  open: boolean
  onChange: (value: string) => void
  anchorElement: HTMLElement | null
}>(function MenuList({ menuItems, anchorElement, open, handleClose, onChange }) {
  return (
    <StyledMenu
      aria-labelledby="navigation-menu"
      anchorEl={anchorElement}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {menuItems.map(({ icon, label, value }, idx) => (
        <MenuItem key={idx}>
          <ListItemIcon>
            <Avatar icon={icon} size="30" />
          </ListItemIcon>
          <ListItemText onClick={() => onChange(value)}>{label}</ListItemText>
        </MenuItem>
      ))}
    </StyledMenu>
  )
})

const DialogMenuList = memo<{
  menuItems: Array<{
    icon?: ComponentProps<typeof Avatar>['icon']
    label: string
    value: string
  }>
  handleClose: () => void
  open: boolean
  onChange: (value: string) => void
  anchorElement: HTMLElement | null
}>(function MenuList({ menuItems, open, handleClose, onChange }) {
  if (!open) return
  return (
    <LegacyDialog onClose={handleClose} fullWidth>
      {menuItems.map(({ icon, label, value }, idx) => (
        <MenuItem key={idx}>
          <ListItemIcon>
            <Avatar icon={icon} size="30" />
          </ListItemIcon>
          <ListItemText onClick={() => onChange(value)}>{label}</ListItemText>
        </MenuItem>
      ))}
    </LegacyDialog>
  )
})
